import React from 'react';

import { StaticImage } from "gatsby-plugin-image"

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-xl-4 col-lg-5 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                <strong>Followers Hub</strong>
                <br />
                <span className="subtitle">
                  Followers Tracker for Instagram
                </span>
              </h1>
              <Scroll type="id" element="download">
                <a href="#download" className="btn btn-outline btn-xl">
                  Download
                </a>
              </Scroll>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 my-auto">
            <StaticImage 
              src="../assets/images/demo-collage.png" 
              loading="eager"
              placeholder="none"
              alt="" 
            />
          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Discover Your Instagram Followers!
            </h2>
            <p className="text-muted">Download now to get started!</p>
            <br/>
            <div className="badges">
              <a
                className="badge-link"
                href="https://apps.apple.com/us/app/followers-hub-track-followers/id1568936625"
              >
                <img src={astore} alt="" />
              </a>
              <a
                className="badge-link"
                href="https://play.google.com/store/apps/details?id=com.followershub"
              >
                <img src={gplay} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Followers Tracker for Instagram</h2>
          <p className="text-muted">
            The most insightful report to track your Instagram followers and
            analyze your posts!
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-wrapper">
              <div
                className="device"
                data-device="iPhoneX"
                data-orientation="portrait"
                data-color="black"
              >
                <div className="screen">
                  <StaticImage 
                    src="../assets/images/demo-home.jpg" 
                    placeholder="blurred"
                    alt="" 
                  />
                </div>
                <div className="button"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Secret Admirers</h3>
                    <p className="text-muted">
                      Check out who liked your posts but didn't follow you yet
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Follower Activites</h3>
                    <p className="text-muted">
                      Track your new/lost followers, who blocks you and more
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>User Insights</h3>
                    <p className="text-muted">
                      Find out your best followers, ghost followers and more
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Post Insights</h3>
                    <p className="text-muted">
                      Find out your most and least popular posts
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          We <i className="fas fa-heart"></i> new friends!
        </h2>
        <Social />
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
